import React from 'react'
import { graphql } from 'gatsby'
import { PostList } from '../components/PostList'

const BlogIndex = ({ data, location }) => {
  return <PostList data={data} pageTitle="All Posts" location={location} />
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        ...IndexPostFragment
      }
    }
  }
`
